import React, { type ComponentType, useEffect } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { useExperienceFail } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { useExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import { RIGHT_SIDE_PANEL_EXPERIENCE } from './constants';

const LazyRightSidePanel = lazyForPaint<ComponentType<{}>>(
	() => import(/* webpackChunkName: "async-jira-home-right-side-panel" */ './index'),
);

export const AsyncRightPanel = () => {
	const startExperience = useExperienceStart(RIGHT_SIDE_PANEL_EXPERIENCE);
	const onFail = useExperienceFail(RIGHT_SIDE_PANEL_EXPERIENCE);

	useEffect(() => {
		startExperience();
	}, [startExperience]);

	return (
		<JSErrorBoundary
			id="right-side-panel"
			packageName="jira-home-right-side-panel"
			fallback="unmount"
			onError={onFail}
		>
			<Placeholder name="async-jira-home-right-side-panel" fallback={null}>
				<LazyRightSidePanel />
			</Placeholder>
		</JSErrorBoundary>
	);
};
